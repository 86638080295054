import React, { useEffect, useState } from 'react'
import api from '../api';
import { useAlert } from 'react-alert';
import { Loader } from './global/loader';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import userAction from "../redux/users/action";

export const UserPost = () => {
  const alert = useAlert();
  const userStore = useSelector((state) => state.userReducer);
  const dispatch = useDispatch()
const { setUser } = userAction;

  const [loader,setLoader]=useState(true)
  const [page, setPage]=useState(1)
  const [limit, setLimit]=useState(10)
  const [data, setData]=useState([])
  const [noOfPost, setnoOfPost]=useState(0)

  const getPosts = async()=>{

    try {
      let payload = {
        page:page
      }
      setLoader(true)
      let checkSession = await checkUserSession()
      if(checkSession) {
        const postData = await api.postGet(payload)
        // console.log(postData);
        setData([ ...data,...postData.post])
        setnoOfPost(postData.count)
        setLimit(postData.limit)
        setLoader(false)
      }else{
        alert.show("Session Expired")
      }
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }

  const handleChange = (value) => {
    setPage(value);
  };

  useEffect(() => {
    getPosts()
  
  }, [page])

  const checkUserSession = async() => {
    let token=localStorage.getItem("token")
    // console.log(token);
    if(token){
      let payload = {token:token}
      const registerationResponse = await api.verifySession(payload);
      console.log(registerationResponse);
      if(registerationResponse?.token){
        axios.defaults.headers.common["Authorization"]=registerationResponse?.token
        return true
      }else{
        dispatch(setUser({}));
        localStorage.removeItem('token')
        return false
      }
    }
  }
// console.log(page);
  return (
    <>
    <div className="contact-widget-area pb-70">
        <div className="container">
            {userStore?.users?.user?.wallet ?
            <>
            <div className="section-title text-center mb-45 mt-4">
                <span />
                <h2 className='text-uppercase'>waqar zaka updates</h2>
            </div>
            <div className="userPostSec">
                <div className="row">
                    {data.map((item,key)=>(
                    <div className="col-md-6 mb-3" key={key}>
                        <div className="postCard">
                            {item?.imageTitle &&
                            <div className="imgDiv">
                                <img 
                                src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${item.imageTitle}.${item.imageFormat}`} 
                                alt="" />                            
                            </div>
                            }
                            <div className="desc">
                                <div dangerouslySetInnerHTML={{ __html: item?.description }} />
                                <div className="date"> {moment(item?.createdAt).fromNow()}</div>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
            </>
            :
            <h2 className='addWalletText'>Please Add your wallet to get vip access</h2>
            }
            {((limit * page) < noOfPost) &&
            <div className="showMoreBtn text-center mt-5">
                <button className="default-btn" onClick={()=> handleChange(page+1)}>Show More</button>
            </div>
            }
        </div>
    </div>
    {loader && 
        <Loader />
    }
    </>
  )
}
