import React from 'react'
import logo2 from "../images/logo-2.png"

export const Footer = () => {
  return (
    <footer className="footer-area">
    <div className="container pt-100 pb-70">
      <div className="row">
        <div className="col-lg-3 col-sm-6">
          <div className="footer-widget">
            <div className="footer-logo">
              <a href="#">
                <img src={logo2} alt="Images" />
              </a>
            </div>
            <p> DISCLAIMER: We are an Educational Platform/Teachers, We dont ask for investments and we are not financial advisors. The Following groups are the only offical groups of Waqar Zaka. </p>
            <ul className="social-link">
              <li className="social-title">Follow Us:</li>
              <li>
                <a href="https://www.facebook.com/waqarzaka/" target="_blank">
                  <i className="fa-brands fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/zakawaqar" target="_blank">
                  <i className="fa-brands fa-twitter" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/waqarzaka/?hl=en" target="_blank">
                  <i className="fa-brands fa-instagram" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="footer-widget ps-5">
            <h3>Resources</h3>
            <ul className="footer-list">
              <li><a href="#"> Home </a></li>
              <li><a href="#services-course"> Courses </a></li>
              <li><a href="#about"> About </a></li>
              <li><a href="#testimonial"> Testimonial </a></li>
              <li><a routerlink="contactus" href="/contactus"> Web 3 </a></li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="footer-widget ps-5">
            <h3>Official Info</h3>
            <ul className="footer-contact">
              {/* <li><i className="fa-solid fa-location-dot" />
                <div className="content">
                  <h4>Location:</h4>
                  <span>DSO_IFZA IFZA Properties Dubai Silicon Oasis</span>
                </div>
              </li> */}
              <li>
                <i className="fa-solid fa-envelope"></i>
                <div className="content">
                  <h4>Email:</h4>
                  <span>info@waqarzaka.net</span>
                  </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="copyright-area">
      <div className="container">
        <div className="copy-right-text text-center">
          <p> Copyright © 2023 <b>Waqar Zaka</b> All Rights Reserved </p>
        </div>
      </div>
    </div>
  </footer>
  )
}
