import React,{useEffect,useState} from 'react';
import { NavLink } from "react-router-dom";
import ReactQuill from 'react-quill';
import api from '../../api';
import Logo from "../../images/logo-2.png"
import 'react-quill/dist/quill.snow.css';


function PromotionMail({match}) {
  const [value, setValue] = useState('');
  const [subject, setSubject] = useState('');
  const [loader,setLoader]=useState(false)

  const handleSubmit = async() => {
    try {
        
        if(value && subject){
            setLoader(true)
            let payload ={
                template:value,
                subject:subject,
            }
            // console.log(payload);
            const userData = await api.sendMailToAllUser(payload)
            // console.log(userData);
            if(userData.success){
                alert(userData.message)
                setLoader(false)
              }else{
                alert("Something went wrong, email not sent ")
                setLoader(false)
            }
            // console.log(value);
        }else{
            setLoader(false)
            alert("Please insert fields");
        }
    } catch (error) {
        setLoader(false)
        console.log(error);
        alert("Something went wrong, email not sent ")

    }

  }

    var Editor = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }
  

    return(
      <>
                  {/* Begin Page Content */}
                    <div className="container-fluid">
                     {/* Content Row */}
                      <div className="row">
                      {/* Area Chart */}
                      <div className="col">
                         <div className="row mx-0 drivers-row">
                             <div className="col-xl-6 col-md-7  d-flex justify-content-between pr-0">
                                 <h4 className="color-dark fs-17 dib font-weight-bold mr-5">Send Mail To All User</h4>
                              
                             </div>
                         </div>
                         <div className="card sortby-box br-10 border-transparent mt-3"> 
                            <div className="card-header br-10  bg-white border-transparent py-3 ">
                              <div className="form-group mb-3">
                                <input type="text" className='form-control' onChange={(e)=>setSubject(e.target.value)} placeholder='Subject...' />
                              </div>
                                <ReactQuill placeholder='Add Email description' modules={Editor} theme="snow" value={value} onChange={setValue} />
                                <div className="sendMailBtn mt-3">
                                    <button onClick={handleSubmit} className='btn-dark'>Send Mail</button>
                                </div>
                            </div>
                         </div>
                         {/* table */}

                      </div>
                      
                    </div>
              
                  </div>
                  {/* /.container-fluid */}
                  {loader &&
                    <div className="seekoLoader">
                        <div className="loader">
                            <img src={Logo} alt="loader" />
                        </div>
                    </div>
                }
      </>
    )
  }

  export default PromotionMail