import React, { useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import api from "../api/index"
import { useAlert } from "react-alert";
import OtpInput from "react-otp-input";
import Modal from "react-bootstrap/Modal";
import { OtpModal } from './otpModal';
import CountrySelect from './countrySelect';
import { SliderThumb, TextField } from '@mui/material';
import axios from 'axios';
import showPassImg from "../images/showPass.svg"
import hidePass from "../images/hidePass.svg"
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import { useEffect } from 'react';
import WAValidator from 'multicoin-address-validator';

export const ResetPass = () => {
  const alert = useAlert();
  const {id} = useParams();

  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [loader, setLoader] = useState(false);


  const validators = () => {
    
    if (!data.password) {
      return "Password is required";
    }
    if (data.password && data.password.length < 8) {
      return "Password must be 8 characters long";
    }
    if (data.password && data.password.length > 30) {
      return "Password must be less than 30 characters long";
    }
    if (!data.confirmPassword) {
      return "Confirm password is required";
    }
    if (data.password !== data.confirmPassword) {
      return "Password and Confirm password not matched";
    }
    if(data.password.includes(' ')){
        return "Spaces are not allowed in password";
     }

    return false;
  };

  const onTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    try {
        e.preventDefault();
      const validatorResponse = await validators();
      
      if (validatorResponse) {
        return alert.show(validatorResponse);
      }
      
      setLoader(true);
      const payload = {
        ...data,
        token:id
      };
      const response = await api.resetPass(payload);
      console.log(response);
      if (response?.error) {
        console.log(response?.error);
        setLoader(false)
      }
      if (response?.message == "Link expired") {
          setLoader(false)
          navigate("/")
        return alert.show(response?.message);
      }
      if(response?.success){
        alert.show("Password saved, now you can login.")
        setLoader(true)
        setTimeout(() => {
          setLoader(false)
          navigate("/login");
        }, 3000);
      }else{
        alert.show(response?.message)
        setLoader(false)
      }

    } catch (e) {
      setLoader(false);
     
      alert.show("Error while Reset Password")
    }
  };

  return (
    <>
    <div className="contact-widget-area pb-70">
        <div className="container">
            <div className="section-title text-center mb-45">
                <span />
                <h2 className='text-uppercase'>Reset Password</h2>
            </div>
            <div className="contact-form signup-form">
                <form noValidate id="contactForm" className="ng-invalid ng-dirty ng-touched">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group showPassInput">
                                <input onChange={(e)=>onTextChange(e)} type={showPass?"text":"password"} name="password" id="password" required  placeholder="Password" className="form-control ng-dirty ng-invalid ng-touched" />
                                {showPass?
                                <img onClick={()=>setShowPass(false)} src={showPassImg} alt="" />
                                :
                                <img onClick={()=>setShowPass(true)} src={hidePass} alt="" />
                                }
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group showPassInput">
                                <input onChange={(e)=>onTextChange(e)} type={showPass?"text":"password"} name="confirmPassword" id="password" required  placeholder="Confirm Password" className="form-control ng-dirty ng-invalid ng-touched" />
                                {showPass?
                                <img onClick={()=>setShowPass(false)} src={showPassImg} alt="" />
                                :
                                <img onClick={()=>setShowPass(true)} src={hidePass} alt="" />
                                }
                            </div>
                        </div>
             
                        <div className="col-lg-12 col-md-12 text-center">
                            <button onClick={onSubmit} type="submit" className="default-btn"  >
                              {loader && 
                              <i className="fa fa-spinner fa-pulse mx-2 mb-1" style={{position:"relative",top:"-0px"}}></i> 
                              }
                               Submit 
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    </>
  )
}


