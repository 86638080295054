import React,{useEffect,useState} from 'react';
import { NavLink } from "react-router-dom";
import axios from "axios";


import edit from "../../images/edit.svg";
import Delete from "../../images/delete.svg";
import Logo from "../../images/logo-2.png"
import { useSelector } from 'react-redux';
import api from '../../api';
import { Pagination, Stack } from '@mui/material';
import { Loader } from './loader';
import moment from 'moment';


function LockersList({match}) {
  const [page, setPage]=useState(1)
  const [limit, setLimit]=useState(10)
  const [data, setData]=useState([])
  const [noOfUser, setnoOfUser]=useState(0)
  const [loader,setLoader]=useState(true)
  const [searchMail,setSearchMail]=useState("")
  const [resetPage,setResetPage]=useState(false)

  // console.log(window.location);
  // console.log(match.url);
  const getLockers = async()=>{
    try {
      let payload = {
        page:page
      }
      setLoader(true)
      const userData = await api.getLocker(payload)
      console.log(userData);
      setData(userData.lockers)
      setnoOfUser(userData.count)
      setLimit(userData.limit)
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }

  const handleSearch = async() => {
    if(searchMail) {
      let payload = {
        email:searchMail
      }
      const res = await api.getLockerByMail(payload)
      console.log(res);
      if(res?.success){
        setData(res?.locker)
      }else {
        alert("Invalid Email Address")
      }
    }else{
      alert("Please insert email for search")
    }
  }

  useEffect(() => {
    getLockers()
  
  }, [page,resetPage])

  const handleReset = () => {
    setResetPage(!resetPage)
    setPage(1)
  }
  

    return(
      <>
                  {/* Begin Page Content */}
                    <div className="container-fluid">
                     {/* Content Row */}
                      <div className="row">
                      {/* Area Chart */}
                      <div className="col">
                         <div className="row mx-0 drivers-row">
                             <div className="col-12   d-flex justify-content-between pr-0">
                                 <h4 className="color-dark fs-17 dib font-weight-bold mr-5">Users who locked TUP 
                                  {/* ({noOfUser}) */}
                                  </h4>
                                 {/* <a href="#" className="driver-row-link">All</a>
                                 <a href="#" className="driver-row-link">Arbitrators (86)</a>
                                 <a href="#" className="driver-row-link">Mediators (32)</a> */}
                                <div className="searchMadmin">
                                  <div className="title">Filter: </div>
                                  <div className="inputM">
                                    <input onChange={(e)=> setSearchMail(e.target.value)} type="text" placeholder='Search by email...' />
                                    <i className="fa-solid fa-magnifying-glass" onClick={handleSearch}></i>
                                  </div>
                                  <button className="reset" onClick={()=>handleReset()}>
                                    <i className="fa-solid fa-arrow-rotate-right"></i>
                                    Reset
                                  </button>
                                </div>
                             </div>
                         </div>
                         {/* table */}
                         <Table data={data} setPage={setPage} page={page} noOfUser={noOfUser} limit={limit} />

                      </div>
                      
                    </div>
              
                  </div>
                  {/* /.container-fluid */}
                  {loader && 
                  <Loader />
                  }
         
      </>
    )
  }
  const Table=({data, page, setPage, noOfUser ,limit})=>{
    // const authedUser = useSelector(s => s.authedUser.authedUser);

    const handleChange = (event, value) => {
      setPage(value);
    };

    const formatDate = (date) => {
      let dateTemp = moment(date).format('MMMM Do YYYY, h:mm:ss a')
      return dateTemp;
    }

    return(
      <>
      <div className="card sortby-box br-10 border-transparent mt-3"> 
        <div className="card-header br-10  bg-white border-transparent py-3 ">
          <div className="table-responsive">
            <table className="table table-hover drivers-table">
              <thead>
                <tr>
                  <th scope="col" className="">Joined At</th>
                  <th scope="col" className="">Email</th>
                  <th scope="col" className="">Locked Amount</th>
                  {/* <th scope="col" className="">Wallet</th>
                  <th scope="col" className="">country</th>
                  <th scope="col" className="">DOB</th>
                  <th scope="col" className="">Gender</th>
                  <th scope="col" className=""><div className='w-max-c'>Email verified</div></th>
                  <th scope="col">Action</th> */}
                </tr>
              </thead>
              <tbody>
              {data.map((item,key)=>(
                <tr key={key}>
                  <td >
                    <div className="table-text-dark w-max-c db">{ formatDate(item?.createdAt) }</div>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db">{item?.email}</span>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db">{item?.lockedAmount ?  item?.lockedAmount:72000 }</span>
                  </td>
{/*              
                  <td>
                    <div className="mr-5">
                      <a href="#" onClick={()=>deleteUser(item?._id)}><img src={Delete} alt="Delete" /></a>
                    </div>
                  </td> */}
                </tr>
              ))}
            
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center">
            <Stack spacing={2}>
              <Pagination count={Math.ceil(noOfUser/limit)} page={page} onChange={handleChange} />
            </Stack>
          </div>
      </div>

    </div>

    
      </>
    )
  }

  export default LockersList