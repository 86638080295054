import { combineReducers } from "redux";

import userReducer from "./users/reducer";
import adminReducer from "./admin/reducer";

const reducers = combineReducers({
  userReducer,
  adminReducer
});

export default reducers;
