import React,{useEffect, useState} from "react";

// import { loginUser, loginUserReq } from "../../../redux/ActionCreators";
import { useDispatch, useSelector } from 'react-redux';
import adminAction from "../../../redux/admin/action";
import { useNavigate } from "react-router-dom";
import axios from "axios";


export function NavbarAdmin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {setAdmin} = adminAction;
  // const authedUser = useSelector(s => s.authedUser.authedUser);
  const logout = () => {
    dispatch(setAdmin({}))
    localStorage.clear()
    navigate("/")
  }

  useEffect(() => {
    let token=localStorage.getItem("token")
      if(token){
        axios.defaults.headers.common["Authorization"]=token;
      }else{
        logout()
      }
  }, [])
  


    return(
        <>
             {/* Topbar */}
             <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top">
                {/* Sidebar Toggle (Topbar) */}
                {/* <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                  <i className="fa fa-bars" />
                </button> */}
              
                {/* Topbar Navbar */}
                <div className="walletBtn ms-auto px-3">
                  <button className="btn" onClick={logout}>
                    logout
                  </button>
                </div>
             
              </nav>
              {/* End of Topbar */}

        </>
    )
    
}
