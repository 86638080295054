import React, { useEffect, useState } from 'react'
import walletImg from "../../images/walletImg.png"
import star from "../../images/star.png"
import { useNavigate } from 'react-router-dom'
import api from '../../api'
import { useAlert } from 'react-alert'
import { useSelector } from 'react-redux'
import Web3 from "web3";
import { Connector, useConnect, useAccount } from 'wagmi'

export const ConnectPage = () => {
    const { address } = useAccount()
    const userStore = useSelector((state) => state.userReducer);
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false);
    const alert = useAlert();
    const { connectors, connect } = useConnect()

    const AddWallet = async (wallet) => {
        // e.preventDefault();
        setLoader(true);
        try {
    
          const payload = {
            userID: userStore?.users?.user?._id,
            wallet: wallet,
          };
          const addResponse = await api.addWallet(payload);
          console.log(addResponse);
          
          if(addResponse?.success) {
                alert.show("Wallet Added")
                navigate(`/portfolio/dashboard/${wallet}`);
                setLoader(false);
            }else{
              alert.error("Something went wrong")

          }
        
        } catch (e) {
          alert.show(e.message);
          // alert.show("Invalid Credentials or Account not verified");
          setLoader(false);
        }
      };

      const logingHandler = async ()=>{

        if (!window.ethereum){
          // alert('Install Metamask');
          alert.error("Install Metamask")
        } else{
          let web3 = new Web3(window.ethereum);
          let accs = await window.ethereum.request({
            method: 'eth_requestAccounts'
          });
          
          if (accs.length > 0){
              let ad = accs[0].toLowerCase();
              console.log(ad);
            AddWallet(ad)
        
          }
        }
        // setisConnecting(false)
      }
      const logingTrustWallet = async (connector)=>{
        connect({ connector })
        if(address){
          AddWallet(address)
        }
      }
      
    useEffect(() => {
      if(address) {
        logingTrustWallet(address)
      }
    }, [address])
    
  return (
    <div className='ConnectPage'>
        <div className="col1">
            <div className="goBack" onClick={()=>navigate(-1)}>
                <i className="fa-solid fa-arrow-left"></i>
            </div>
            <div className="imgDiv">
                <img src={walletImg} alt="wallet" />
            </div>
        </div>
        <div className="col2">
            <img className='star1' src={star} alt="" />
            <img className='star2' src={star} alt="" />
            <div className="content">
                <h3>Which wallet you want to connect</h3>
                <div className="text-center mb-3">
                    <button className="connectBtn" onClick={()=>logingHandler()}>Connect with meta mask</button>
                </div>
                {connectors.map((connector) => (
                  connector.name == "Trust Wallet" &&
                  <div className="text-center mb-3">
                      <button className="connectBtn" key={connector.uid} onClick={() => logingTrustWallet(connector)}>Connect with {connector.name}</button>
                  </div>
                  ))}
                {/* <div className="text-center">
                    <button className="connectBtn">Connect with exchange</button>
                </div> */}
            </div>
        </div>
    </div>
  )
}
