import React, { useState } from 'react'
import { BrowserRouter, Routes, Route, useNavigate, useLocation, useParams } from "react-router-dom";
import { NavbarAdmin } from '../../global/navbar/navbar';
import Sidebar from '../../global/sidebar/sidebar';
import { DataInfo } from '../../global/dataInfo';
import { NavbarPorfoilio } from './porfolioNavbar';
import SidebarPortfolio from './porfolioSidebar';
import { PortfolioPage } from './portfolioPage';
import api from '../../../api';
import { useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import { Loader } from '../../global/loader';

export const PortfolioDashboard = () => {
  const userStore = useSelector((state) => state.userReducer);
  const {wallet} = useParams();
  const alert = useAlert();
  const [loader, setLoader] = useState(false);
  const [ethData, setEthData] = useState({});
  const [bnbData, setBnbData] = useState({});
  const [allwallets, setAllwallets] = useState([]);

  const getPortfolio = async () => {
    // e.preventDefault();
    setLoader(true);
    try {

      const addResponse = await api.getWalletPortfolio(wallet);
      console.log(addResponse);
      
      if(addResponse?.success) {
            setEthData(addResponse?.ethData)
            setBnbData(addResponse?.bnbData)
            setLoader(false);
          }else{
            alert.error("Something went wrong")
            setLoader(false);

      }
    
    } catch (e) {
      alert.show(e.message);
      // alert.show("Invalid Credentials or Account not verified");
      setLoader(false);
    }
  };

  const getWallets = async () => {
    // e.preventDefault();
    setLoader(true);
    try {

      const getResponse = await api.getWallet(userStore?.users?.user?._id);
      console.log(getResponse);
      
      if(getResponse?.success) {
          setAllwallets(getResponse?.res)
          setLoader(false);
        }else{
          alert.error("Something went wrong")
          setLoader(false);

      }
    
    } catch (e) {
      alert.show(e.message);
      // alert.show("Invalid Credentials or Account not verified");
      setLoader(false);
    }
  };

  useEffect(() => {
    if(wallet && userStore?.users?.user?._id){
      getPortfolio()
      getWallets()
    }
  }, [wallet])
  
  return (
    <>
       <div id="wrapper" className='portfolioDashboard'>
            <SidebarPortfolio />
              <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                  <NavbarPorfoilio ethData={ethData} />
                  <PortfolioPage ethData={ethData} bnbData={bnbData} allwallets={allwallets} />
                  {/* <Routes>
                    <Route exact={true} path="/admin/datainfo" element={<DataInfo />} />
                  </Routes> */}
                  
              </div>
          </div>
        </div>
        {loader && <Loader />}
    </>
  )
}
