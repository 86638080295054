import React from 'react'
import Logo from "../../images/logo.png"

export const Loader = ({}) => {
  return (
    <>
          <div className="seekoLoader">
              <div className="loader">
                  <img src={Logo} alt="loader" />
              </div>
          </div>
    </>
  )
}
