import React, { useState } from 'react'
import logo from "../images/logo.png"
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import userAction from "../redux/users/action";
import { Modal } from 'react-bootstrap';

export const Navbar = () => {
  const userStore = useSelector((state) => state.userReducer);
  const { setUser } = userAction;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setShow]=useState(false)
  // const [courseData, setCourseData]=useState([])
    
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const logout = () => {
    dispatch(setUser({}))
    localStorage.clear()
    navigate("/")
  }

  // const handleSetYouLink = () => {
  //   setCourseData(youtubeLinkData)
  // }
  // const handleSetFbLink = () => {
  //   setCourseData(fbLinkData)
  // }


  return (
    <div className="navbar-area ">
    <div className="container-fluid">
      <nav className="navbar navbar-expand-lg navbar-light ">
        <div className="container-fluid">
          <NavLink to={"/"} className="navbar-brand" href="#">
            <img src={logo} alt="" />
          </NavLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink className="nav-link" to="/"> Home </NavLink>
              </li>
              {userStore?.users?.user?._id &&
              <li className="nav-item">
                <NavLink className="nav-link" to="/portfolio"> Portfolio </NavLink>
              </li>
              }
              <li className="nav-item">
                <a className="nav-link" onClick={handleShow} > Courses </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="/#services-courses"> Courses </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" href="/#about"> About </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#testimonial"> Testimonial </a>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="/survey"> Survey </NavLink>
              </li> */}
              {userStore?.users?.user?.wallet &&
              <li className="nav-item">
                <NavLink className="nav-link" to="/post"> Post </NavLink>
              </li>
              }
              <li className="nav-item">
                {userStore?.users?.user?.name ?
                    <div className="logoutDrop">
                      <button className='default-btn name '>
                        {userStore?.users?.user?.name.length >12 ?userStore?.users?.user?.name?.slice(0,12)+" ..." : userStore?.users?.user?.name}
                      </button>
                      <div className="logout" onClick={logout}>Logout</div>
                    </div>
                :
                <>
                <div className="d-flex">
                  <NavLink className="nav-link me-0" to="/signup"> 
                      <button className='default-btn'>Register</button>
                  </NavLink>
                  <NavLink className="nav-link" to="/login"> 
                      <button className='default-btn'>Login</button>
                  </NavLink>
                </div>
                </>
                }
              </li>
            
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body>
        <div className="otpModalMain">
          <div className="closeIcon" onClick={handleClose}>
          <i className="fa-solid fa-xmark" />
          </div>
          <div className="couseModal">
            {/* <div className="mainBtn">
              <div className={courseData[0]?.video ?'groupBtn':'groupBtn active'} onClick={handleSetFbLink}>Facebook Group</div>
              <div className={courseData[0]?.video ?'groupBtn active':'groupBtn'}  onClick={handleSetYouLink}>Youtube Group</div>
            </div> */}
            <div className="linkM">
              {/* <a href={val?.link} target='_blank' className="link" key={key}>{val?.title}</a> */}
              {courseData.map((val,key)=> (
                <div className="accordion" id="accordionExample">
                  <div className="">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="link" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne${key}`} aria-expanded="true" aria-controls={`collapseOne${key}`}>
                       {val?.title}
                      </button>
                    </h2>
                    {val?.link.split(",").length > 1 ?
                    <div id={`collapseOne${key}`} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        {val?.link.split(",").map((item,key1)=>(
                          <a href={item} key1={key1}>{item}</a>
                        ))}
                      </div>
                    </div>
                    : 
                    <div id={`collapseOne${key}`} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <a href={val?.link}>{val?.link}</a>
                      </div>
                    </div>
                    }
                  </div>
                  </div>
              ))}
            </div>
            {/* <div className="videoMain">
              {courseData.map((val,key)=> (
              <div className="video" key={key}>
                {val?.video}
              </div>
              ))}
            </div> */}
            {/* {courseData[0]?.video &&
            <div className='text-center'>
              <a className='default-btn' href="https://www.youtube.com/playlist?list=PLeYUkJPHvOtuiaCtvKTA5cKPqUPwYphLC" target="_blank" rel="noopener noreferrer">Playlist</a>
            </div>
            } */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  </div>
  )
}
const courseData = [
  {
    title:"What is a Exchange?",
    link: "https://www.facebook.com/waqarzaka/videos/597929235762486/?idorvanity=932060957279680" ,
  },
  {
    title:"What is wallet?",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/permalink/1449362605549510/?app=fbl" ,
  },
  {
    title:"How to register at Bitget?",
    link: "https://fb.watch/vT1qIAl-Ha/" ,
  },
  {
    title:"How to transfer funds to Bitget?",
    link: "https://fb.watch/vT1vA0xc1g/" ,
  },
  {
    title:"How to do Spot,Futures and Demo trading?",
    link: "https://fb.watch/vT1wnaYy7V/" ,
  },
  {
    title:"How to calculate liquidation price?",
    link: "https://fb.watch/vT1xpbYFaP/" ,
  },
  {
    title:"How to use Bitget Support for any issue?",
    link: "https://fb.watch/vT1yai8SmR/" ,
  },
  {
    title:"Tradingview",
    link: "https://www.facebook.com/100025467665160/videos/765525544964558/" ,
  },
  {
    title:"Trading basics",
    link: "https://fb.watch/vT1BEenY1U/" ,
  },
  {
    title:"Portfolio Management",
    link: "https://fb.watch/vT1CSpTYhy/" ,
  },
  {
    title:"Candlesticks and Price action",
    link: "https://fb.watch/vT1DOi7nw-/" ,
  },
  {
    title:"Future trading and practice",
    link: "https://fb.watch/vT1EupRNK5/" ,
  },
  {
    title:"Support and Resistance",
    link: "https://fb.watch/vT1FdiXwEE/" ,
  },
  {
    title:"Chart patterns",
    link: "https://fb.watch/vT1FVf250S/https://fb.watch/vT1GYDslOX/,https://fb.watch/vT1HkiY2E0/,https://fb.watch/vT1HM9OxDL/,https://fb.watch/vT1IAJELNw/,https://fb.watch/vT1J0p4D8E/,https://fb.watch/vT1JuPgNGi/," ,
  },
  {
    title:"RSI",
    link: "https://fb.watch/vT1JRoAeho/" ,
  },
  {
    title:"ATR",
    link: "https://fb.watch/vT1KCESt-c/" ,
  },

 
]
