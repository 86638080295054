import React, { useRef, useState } from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './testimonials.css';

// import required modules
import { Pagination, Autoplay, Navigation } from 'swiper/modules';

import sayAbout1 from "../images/sayAbout1.png"
import sayAbout2 from "../images/sayAbout2.png"
import sayAbout3 from "../images/sayAbout3.png"
import sayAbout4 from "../images/sayAbout4.png"
import sayAbout5 from "../images/sayAbout5.png"
import sayAbout6 from "../images/sayAbout6.png"


function Testimonials() {
    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };

    return <>
        <Swiper
            className="mySwiper py-5"
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            onAutoplayTimeLeft={onAutoplayTimeLeft}
            slidesPerView={1}
            spaceBetween={10}
            breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
        >
            <SwiperSlide>
                <div className="card">
                    <div className="card-body">
                        <img src={sayAbout4} alt="" className="img-fluid" />
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="card">
                    <div className="card-body">
                        <img src={sayAbout5} alt="" className="img-fluid" />
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="card">
                    <div className="card-body">
                        <img src={sayAbout6} alt="" className="img-fluid" />
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="card">
                    <div className="card-body">
                        <img src={sayAbout1} alt="" className="img-fluid" />
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="card">
                    <div className="card-body">
                        <img src={sayAbout2} alt="" className="img-fluid" />
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="card">
                    <div className="card-body">
                        <img src={sayAbout3} alt="" className="img-fluid" />
                    </div>
                </div>
            </SwiperSlide>
            {/* <SwiperSlide>
                <div className="slidecard">
                    <div className="content">
                        “Teachings of the great explore of truth,
                        the master-builder of human happiness.
                        no one rejects,dislikes, or avoids pleasure
                        itself, pleasure itself”
                    </div>
                    <div className="user">
                        <div className="img">
                            <img src={testimonail1} alt="testimonail1" />
                        </div>
                        <div className="data">
                            <span className='h'>Finlay Kirk</span>
                            <span className='p'>Student Future Trading</span>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="slidecard">
                    <div className="content">
                        “Complete account of the system and
                        expound the actual Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots”
                    </div>
                    <div className="user">
                        <div className="img">
                            <img src={testimonail2} alt="testimonail2" />
                        </div>
                        <div className="data">
                            <span className='h'>Dannette P. Cervantes</span>
                            <span className='p'>Student Future Trading</span>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="slidecard">
                    <div className="content">
                        “Teachings of the great explore of truth,
                        the master-builder of human happiness.
                        no one rejects,dislikes, or avoids pleasure
                        itself, pleasure itself”
                    </div>
                    <div className="user">
                        <div className="img">
                            <img src={testimonail1} alt="testimonail1" />
                        </div>
                        <div className="data">
                            <span className='h'>Finlay Kirk</span>
                            <span className='p'>Student Future Trading</span>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="slidecard">
                    <div className="content">
                        “Complete account of the system and
                        expound the actual Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots”
                    </div>
                    <div className="user">
                        <div className="img">
                            <img src={testimonail2} alt="testimonail2" />
                        </div>
                        <div className="data">
                            <span className='h'>Dannette P. Cervantes</span>
                            <span className='p'>Student Future Trading</span>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="slidecard">
                    <div className="content">
                        “Teachings of the great explore of truth,
                        the master-builder of human happiness.
                        no one rejects,dislikes, or avoids pleasure
                        itself, pleasure itself”
                    </div>
                    <div className="user">
                        <div className="img">
                            <img src={testimonail1} alt="testimonail1" />
                        </div>
                        <div className="data">
                            <span className='h'>Finlay Kirk</span>
                            <span className='p'>Student Future Trading</span>
                        </div>
                    </div>
                </div>
            </SwiperSlide> */}
            <div className="autoplay-progress" slot="container-end">
                <svg viewBox="0 0 48 48" ref={progressCircle}>
                    <circle cx="24" cy="24" r="20"></circle>
                </svg>
                <span ref={progressContent}></span>
            </div>
        </Swiper>
    </>

}

export default Testimonials;