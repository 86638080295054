import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import api from '../api';
import axios from "axios";
import userAction from "../redux/users/action";
import { OtpModal } from './otpModal';
import showPassImg from "../images/showPass.svg"
import hidePass from "../images/hidePass.svg"

export const ForgetPass = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const userStore = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

    const [data, setData] = useState({
        email: "",
      });
  const [loader, setLoader] = useState(false);


    const validators = () => {
        if (!data.email) {
          return "Email is required";
        }
        return false;
      };
    
      const onTextChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
      };

      const submitForgetPass = async(e)=>{
        try {     
            e.preventDefault();
            let check = validators()
            if(check){
                alert.show(check)
                return
            }
            setLoader(true)      
            var expression = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
            if (expression.test(data.email)){
                console.log(data.email);
                const res = await api.forgetPass({email:data.email})
                console.log(res);
                if(res?.success){
                    setLoader(false)
                    alert.show("Please check your email to reset password.")
                    setData({})
                }
                
            }
            else{
                alert.show('Invalid email address');
                setLoader(false)
            }
        } catch (error) {
            console.log(error);
            alert.show("Something went wrong.")
            setLoader(false)
        }
	}
      
  return (
    <>
    <div className="contact-widget-area pb-70">
        <div className="container">
            <div className="section-title text-center mb-45">
                <span />
                <h2 className='text-uppercase'>Forget Password</h2>
            </div>
            <div className="contact-form signup-form">
                <form noValidate id="contactForm" className="ng-invalid ng-dirty ng-touched">
                    <div className="row">
                     
                        <div className="col-12">
                            <div className="form-group">
                                <input onChange={onTextChange} type="text" name="email" id="email" required  placeholder="Email" className="form-control ng-dirty ng-invalid ng-touched" />
                            </div>
                        </div>
             
                        <div className="col-lg-12 col-md-12 text-center">
                            {loader && 
                              <i className="fa fa-spinner fa-pulse mx-2 mb-1" style={{position:"relative",top:"-0px"}}></i> 
                              }
                            <button onClick={submitForgetPass} type="submit" className="default-btn"> Submit </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    </>
  )
}
