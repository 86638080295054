import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const AuthNotLoginRoutes = (props) => {
    const userStore = useSelector((state) => state.userReducer);
    // 1: logged in and not email verified not 2fa verified
    // 2: logged in and  2fa verfied email not verified
    // 3: logged in and  2fa verfied email verified
    // 4: logged in and  not 2fa verfied email verified
  
    return ((userStore?.users?.token)) ? (
        <Navigate to="/" />
        ) : (
        <Outlet />
    );
  };

export default AuthNotLoginRoutes;
